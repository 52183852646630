import React from "react";
import { withStyles, withTheme } from "@material-ui/core/styles";

interface IColorThresholdsInfo {
  warning: number;
  error: number;
  isLess: boolean;
}

export type Range = IColorThresholdsInfo | null | undefined;

interface ProgressBarProps {
  range: Range;
  currentValue: number | string;
  maxValue?: number;
  theme?: any;
  classes: any;
}

const styles = (theme: any): any => ({
  container: {
    width: "150px",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    padding: "4px 0",
    color: theme.newPalette.isDark ? "#243544" : "rgba(255, 255, 255, 0.92)",
    fontSize: "10px",
  },
  barContainer: {
    position: "relative",
    height: "10px",
    display: "flex",
    borderRadius: "16px",
    overflow: "hidden",
  },
  redBar: {
    backgroundColor: theme.newPalette.error.main,
    height: "100%",
  },
  yellowBar: {
    backgroundColor: theme.newPalette.warning.main,
    height: "100%",
  },
  greenBar: {
    backgroundColor: theme.newPalette.success.main,
    height: "100%",
  },
  currentValueMarker: {
    width: "2px",
    height: "10px",
  },
  labelsContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "4px",
  },
  rangeLabel: {
    position: "absolute",
    transform: "translateX(-50%)",
  },
  currentValueLabel: {
    textAlign: "left",
    fontWeight: 600,
  },
  currentValueContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: "max-content",
  },
});

const ProgressTooltip: React.FC<ProgressBarProps> = ({ range, currentValue, maxValue = 100, theme, classes }) => {
  if (!range) {
    return null;
  }

  const { warning, error, isLess } = range;
  const [start, end] = isLess ? [error, warning] : [warning, error];

  const max = end > maxValue ? end + (end - start) : maxValue;

  const getPercentage = (value: number) => Math.min((value / max) * 100, 100);

  const startPercentage = getPercentage(start);
  const endPercentage = getPercentage(end);
  const currentPercentage = getPercentage(Number(currentValue));

  const getMarkerColor = () => {
    if (isLess) {
      if (currentPercentage < startPercentage) return theme.newPalette.success.main;
      if (currentPercentage < endPercentage) return theme.newPalette.error.main;
      return theme.newPalette.success.main;
    } else {
      if (currentPercentage < startPercentage) return theme.newPalette.success.main;
      if (currentPercentage < endPercentage) return theme.newPalette.error.main;
      return theme.newPalette.success.main;
    }
  };

  return (
    <div className={classes.container}>
      <div
        className={classes.currentValueContainer}
        style={{ marginLeft: `${currentPercentage > 10 ? `calc(${currentPercentage}% - 10px)` : `${currentPercentage}%`}` }}
      >
        <span className={classes.currentValueLabel} style={{ color: getMarkerColor() }}>
          {currentValue}
        </span>
        <div className={classes.currentValueMarker} style={{ backgroundColor: getMarkerColor() }} />
      </div>
      <div className={classes.barContainer}>
        <div className={isLess ? classes.redBar : classes.greenBar} style={{ width: `calc(${startPercentage}% + 5px)` }} />
        <div className={classes.yellowBar} style={{ width: `calc(${Math.min(endPercentage - startPercentage, 100 - startPercentage)}% - 10px)` }} />
        <div className={isLess ? classes.greenBar : classes.redBar} style={{ width: `calc(${Math.max(100 - endPercentage, 0)}% + 5px)` }} />
      </div>
      <div className={classes.labelsContainer}>
        <span>0</span>
        <span className={classes.rangeLabel} style={{ left: `calc(${startPercentage}% + 5px)` }}>
          {start}
        </span>
        <span className={classes.rangeLabel} style={{ left: `calc(${endPercentage}% - 5px)` }}>
          {end}
        </span>
        <span>{max}</span>
      </div>
    </div>
  );
};

export default withTheme()(withStyles(styles)(ProgressTooltip));
